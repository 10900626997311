import { disablePageScroll, enablePageScroll } from 'scroll-lock'

const POPUP_TOGGLE_BUTTON_SELECTOR = '.js-popup-toggle-button'
const ACTIVE_HOOX_CLASS = 'is-popup-hoox'

document.addEventListener('DOMContentLoaded', () => {
  const popupToggleButtonEls = document.querySelectorAll(POPUP_TOGGLE_BUTTON_SELECTOR)
  const bodyEl = document.body

  if (bodyEl && popupToggleButtonEls && popupToggleButtonEls.length) {
    popupToggleButtonEls.forEach(button => {
      button.addEventListener('click', () => {
        bodyEl.classList.toggle(ACTIVE_HOOX_CLASS)
        if (bodyEl.classList.contains(ACTIVE_HOOX_CLASS)) {
          disablePageScroll(bodyEl)
        } else {
          enablePageScroll(bodyEl)
        }
      })
    })
  }
})
